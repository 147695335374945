/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("unused", "MemberVisibilityCanBePrivate", "EXPERIMENTAL_API_USAGE", "EXPERIMENTAL_OVERRIDE")

package features.userVerification.internal

import features.userVerification.models.UserProfileModel
import kangaroorewards.appsdk.core.domain.Result
import kangaroorewards.appsdk.core.io.toModel

internal class UserAccountVerificationServiceImpl : UserAccountVerificationService {

    private val userAccountVerificationRequest = UserAccountVerificationRequest()

    override suspend fun verifyAccount(
        overrideHeaders : Map<String, String>?,
        token: String,
        email: String?,
        phone: String?,
        countryCode: String?    ): Result<UserProfileModel> {
        return userAccountVerificationRequest.verifyAccount(
            overrideHeaders = overrideHeaders,
            token = token,
            email = email,
            phone = phone,
            countryCode = countryCode        ).toModel()
    }
}
